@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mtb30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ptb30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-wrap {
  height: 100%;
  display: flex;
  direction: horizontal;
}

.main-content {
  padding: 50px;
}

.sidebar {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-horiz {
  display: flex;
  flex-direction: row;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

#preview {
  max-width: 80vw;
}
.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


#results-torrent {  display: none;  }

#no-results {   display: none;   }

#finished {  display: none;  }


#uploader {
  width: 400px;
  height: 45px;
}

#myProgress {
  width: 100vw;
  height: 30px;
  background-color: rgb(220, 220, 220);
  max-width: 500px;
}

/* Buttons */
#submit-torrents, #reload,#get-magnets, #log { display: none; }

#myBar {
  width: 0%;
  height: 30px;
  background-color: rgb(153, 224, 153);
  transition: all 1s cubic-bezier(0.1, 0.26, 0.45, 0.94);
}



.lds-ring {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 4px solid rgb(30, 30, 30);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(30, 30, 30) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wrapper {margin-bottom: 100px;}

.flex-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  }

.flex-horiz {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
}

.colorbox {
  width: 215px;
  overflow: hidden;
  border-width: 2px;
  border-color: grey;
  border: 1px solid grey;
  padding: 15px;
}

.color {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: lightblue;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.color > p {
  text-align: center;
  display: none;
}

.copy {
  width: 100%;
  margin-bottom: 4px;
}

h5 {
  text-transform: capitalize;
  text-overflow: clip;
  white-space: nowrap;
}

#name-form {
  max-width: 500px;
}

.color-full {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  display: none;
}

#btn-name {  border-top-left-radius: 0px; border-bottom-left-radius: 0px;   }

#pName { border-right: 0px;}

.colorbox > p {cursor: pointer;}

.empty {display: none}

.torrent-list {
  width: 95vw;
}

.tlist-item {
  white-space:nowrap;
}

.sleepbtn {
  width: 300px;
  height: 300px;
  border-radius: 300px;
}
